.cs-avatar img {
    object-fit: cover;
}

.cs-conversation__info {
    margin-top: 4px;
}

.message-content__scout .cs-message__content {
    padding: 0;
    overflow: hidden;
    border-radius: 4px !important;
    border: none;
}

.message-content__scout .message-content__sending-status {
    display: none;
}

@media (max-width: 768px) {
    .cs-main-container {
        border: none;
    }

    .cs-main-container--responsive .cs-search {
        display: flex;
    }

    .cs-conversation__content {
        margin-left: 8px;
    }

    .cs-main-container--responsive > .cs-sidebar.cs-sidebar--left {
        display: none;
    }

    .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__back {
        display: flex;
    }

    .cs-conversation-header__back {
        display: flex;
        margin-right: 20px;
        width: 42px;
        justify-content: center;
        align-items: center;
    }

    .cs-conversation-header__back button {
        padding: 3px;
        font-size: 3rem;
    }
}

.cs-message--outgoing .cs-message__content {
    background-color: #DAE9FF !important;
}
